/* eslint-disable no-param-reassign */
import axios from 'axios';
import FormData from 'form-data';
import fileDownload from 'js-file-download';
import * as Sentry from '@sentry/react';

import { ACTION_TYPES } from '../context/AppContext';
import { NOTIFICATION_ACTION_TYPES } from '../routes/Dashboard/Notifications/Context';
import { JOBS_TYPES } from '../routes/Dashboard/Jobs/Context';
import { CODES_TYPES } from '../routes/Dashboard/Codes/Context';
import { USERS_TYPES } from '../routes/Dashboard/Users/Context';
import { COMPANIES_TYPES } from '../routes/Dashboard/Companies/Context';
import { getTokens, setTokens } from './utils/helpers';
import uploadFile from './utils/uploadFile';
import { ANALYTICS_TYPES } from '../routes/Dashboard/Analytics/Context';

class ApiError extends Error {
  // TODO: Implement Sentry error and exception handling for ApiErrors
  constructor({ status, message, data }) {
    super();
    this.status = status;
    this.message = message;
    this.data = data;
  }
}

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Authorization: getTokens().accessToken
      ? `Bearer ${getTokens().accessToken}`
      : '',
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  xsrfHeaderName: 'X-CSRFTOKEN',
  xsrfCookieName: 'csrftoken'
});

const forceLogout = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  window.location = '/login';
};

const handleApiResponse = error => {
  if (error.response) {
    console.warn('response error: ', error.toJSON());
    if (error.response.status === 401 && getTokens().accessToken === null) {
      window.location = '/login';
      throw new ApiError({
        status: error.response.status,
        message: 'Unauthorized user attempting to make API call!',
        data: error.response.data
      });
    } else if (error.response.data) {
      const errorObject = Object.values(error.response.data)[0];
      if (
        typeof errorObject === 'string' &&
        errorObject === 'Given token not valid for any token type'
      ) {
        // Handle "Given token not valid for any token type" error and force re-obtain token
        // if access token and refresh token are both expired, that triggers the "given token" error
        // in that scenario, make the user login again
        forceLogout();
      } else {
        throw new ApiError({
          status: error.response.status,
          message:
            typeof errorObject === 'object' ? errorObject[0] : `${errorObject}`,
          data: error.response.data
        });
      }
    }
  } else if (error.request) {
    console.warn('request error: ', error.request);
    throw new ApiError({
      status: 500,
      message:
        'No response received! Please try your action again, or refresh the page.',
      data: error.request
    });
  } else {
    console.warn('other error: ', error.message);
    throw new ApiError({
      status: 500,
      message:
        'An unknown error occurred! Please try your action again, or refresh the page.',
      data: error.response.data
    });
  }
};

const handleTokenValidity = async () => {
  try {
    if (getTokens().accessToken) {
      await apiClient.request({
        url: '/auth/token/verify/',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${getTokens().accessToken}`
        },
        data: { token: getTokens().accessToken }
      });
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      if (getTokens().refreshToken) {
        try {
          const refreshResponse = await apiClient.request({
            url: '/auth/token/refresh/',
            method: 'POST',
            headers: {
              Authorization: `Bearer ${getTokens().accessToken}`
            },
            data: { refresh: getTokens().refreshToken }
          });
          setTokens(refreshResponse.data.access, refreshResponse.data.refresh);
        } catch (error) {
          if (error.response && error.response.status === 401) {
            forceLogout();
          }
        }
      } else {
        forceLogout();
      }
    }
  }
};

export const makeApiRequest = async (
  method,
  url,
  body = null,
  queryParams = {}
) => {
  await handleTokenValidity();
  try {
    const response = await apiClient.request({
      url,
      method,
      headers: {
        Authorization: getTokens().accessToken
          ? `Bearer ${getTokens().accessToken}`
          : ''
      },
      params: queryParams,
      data:
        ['POST', 'PUT', 'PATCH', 'DELETE'].includes(method) && body
          ? JSON.stringify(body)
          : undefined
    });
    return response.data;
  } catch (error) {
    handleApiResponse(error);
  }
};

export const uploadToApi = async ({
  url,
  file,
  onUploadProgress,
  ahjs = [],
  isVerification
}) => {
  const formData = new FormData();
  formData.append('files', file);
  if (ahjs.length > 0) {
    formData.append('ahjs', ahjs);
  }
  if (isVerification) {
    formData.append('verification_file', isVerification);
  }
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    method: 'POST',
    onUploadProgress,
    headers: {
      Authorization: getTokens().accessToken
        ? `Bearer ${getTokens().accessToken}`
        : '',
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    },
    xsrfHeaderName: 'X-CSRFTOKEN',
    xsrfCookieName: 'csrftoken'
  });
  await handleTokenValidity();
  return axiosInstance.post(url, formData, {
    headers: {
      Authorization: getTokens().accessToken
        ? `Bearer ${getTokens().accessToken}`
        : '',
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const uploadSingleFile = async uploadInfo => {
  const file = { ...uploadInfo };
  delete file.events;
  const callbackEvents = { ...uploadInfo.events };
  try {
    const uploadedFile = await uploadFile({
      url: file.url,
      file: file.file,
      events: callbackEvents
    });
    return uploadedFile;
  } catch (err) {
    console.error(err);
  }
  return null;
};

export const uploadMultipleFiles = async (files, url, events) => {
  try {
    const formData = new FormData();

    if (Array.isArray(files) && files.length > 0) {
      files.forEach(file => {
        formData.append('files', file.file);
      });
    } else {
      Object.keys(files).forEach(key => {
        if (Array.isArray(files[key])) {
          files[key].forEach(file => {
            formData.append(key, file);
          });
        }
      });
    }

    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      method: 'POST',
      headers: {
        Authorization: getTokens().accessToken
          ? `Bearer ${getTokens().accessToken}`
          : '',
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      },
      xsrfHeaderName: 'X-CSRFTOKEN',
      xsrfCookieName: 'csrftoken'
    });
    await handleTokenValidity();

    const fileResponse = await axiosInstance.post(url, formData, {
      onUploadProgress: events.onUploadProgress,
      headers: {
        Authorization: getTokens().accessToken
          ? `Bearer ${getTokens().accessToken}`
          : '',
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    });

    return fileResponse.data;
  } catch (err) {
    console.error(err);
  }
  return null;
};

// TODO: These new functions only get used for the uploadPhotos function, either remove them or use them everywhere
// const CONTENT_TYPES = {
//   json: 'application/json',
//   form: 'application/x-www-form-urlencoded',
//   multipart: 'multipart/form-data'
// };
// const getAuthorizationHeader = () =>
//   getTokens().accessToken ? `Bearer ${getTokens().accessToken}` : '';
// const getHeaders = (contentType = 'json') => {
//   return {
//     Authorization: getAuthorizationHeader(),
//     Accept: 'application/json',
//     'Content-Type': CONTENT_TYPES[contentType] ?? contentType
//   };
// };
// const createAxiosInstance = (method, contentType) => {
//   return axios.create({
//     baseURL: process.env.REACT_APP_API_URL,
//     method,
//     headers: getHeaders(contentType),
//     xsrfHeaderName: 'X-CSRFTOKEN',
//     xsrfCookieName: 'csrftoken'
//   });
// };

// export const uploadPhotos = async (files, url, events) => {
//   try {
//     const formData = new FormData();

//     if (Array.isArray(files) && files.length > 0) {
//       files.forEach(file => {
//         formData.append('photos', file);
//       });
//     } else {
//       Object.keys(files).forEach(key => {
//         files[key].forEach(file => {
//           formData.append(key, file);
//         });
//       });
//     }

//     const axiosInstance = createAxiosInstance('POST', 'multipart');
//     await handleTokenValidity();

//     const fileResponse = await axiosInstance.post(url, formData, {
//       onUploadProgress: events?.onUploadProgress,
//       headers: getHeaders('multipart')
//     });

//     return fileResponse.data;
//   } catch (err) {
//     console.error(err);
//   }
//   return null;
// };

export const uploadSingleAHJFile = async uploadInfo => {
  const file = { ...uploadInfo };
  delete file.events;
  const callbackEvents = { ...uploadInfo.events };
  try {
    const uploadedFile = await uploadFile({
      url: file.url,
      file: file.file,
      events: callbackEvents,
      ahjs: file.ahjIds,
      isVerification: file.isVerification
    });
    return uploadedFile;
  } catch (err) {
    console.error(err);
  }
  return null;
};

export const uploadAHJFiles = async (
  files,
  ahjIds,
  url,
  events,
  isVerification
) => {
  try {
    const appendedFiles = [];
    files.forEach(file => {
      const fileNumber = files.indexOf(file) + 1;
      const currentCountData = {
        fileNumber,
        totalFiles: files.length
      };
      const fileUrl = file.url || url;
      appendedFiles.push({
        ...file,
        ahjIds,
        url: fileUrl,
        events,
        currentCountData,
        isVerification
      });
    });
    const results = await Promise.all(appendedFiles.map(uploadSingleAHJFile));
    return results.map(result => result.data);
  } catch (err) {
    console.error(err);
  }
  return null;
};

export const downloadFile = async (file, enqueueSnackbar) => {
  try {
    const fileUrl = file.content ? file.content.url : file.file;
    const request = new Request(fileUrl, {
      method: 'GET',
      mode: 'cors'
    });
    const response = await fetch(request);
    const downloadResponse = await response.arrayBuffer();
    return downloadResponse;
  } catch (e) {
    console.warn('failed to download! ', e);
    enqueueSnackbar(
      `There was an error downloading ${file.name}. Try downloading it manually.`,
      {
        variant: 'warning'
      }
    );
    return null;
  }
};

// ** Login Management **

export const loginUser = async (data, dispatch) => {
  const loginResponse = await makeApiRequest('POST', '/auth/login/', data);
  setTokens(loginResponse.access, loginResponse.refresh);
  dispatch({
    type: ACTION_TYPES.LOGGED_IN,
    payload: {
      accessToken: loginResponse.access,
      refreshToken: loginResponse.refresh
    }
  });

  if (loginResponse) {
    const userData = await makeApiRequest('GET', '/users/me');
    dispatch({
      type: ACTION_TYPES.UPDATE_USER,
      payload: userData
    });
    dispatch({
      type: ACTION_TYPES.UPDATE_SITE_ALERTS,
      payload: userData.site_alerts
    });
  }
};

export const logoutUser = async dispatch => {
  await makeApiRequest('POST', '/auth/logout/');
  dispatch({
    type: ACTION_TYPES.LOGGED_OUT
  });
  Sentry.setUser(null);
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('activeTab');
  localStorage.removeItem('currentPage');
  localStorage.removeItem('visibleColumns');
  localStorage.removeItem('selectedColumnSort');
  localStorage.removeItem('selectedCompaniesFilter');
  localStorage.removeItem('selectedStatusFilter');
  localStorage.removeItem('selectedAssignmentFilter');
  localStorage.removeItem('selectedUserFilter');
  localStorage.removeItem('selectedStateFilter');
  localStorage.removeItem('selectedIsRevisionFilter');
  localStorage.removeItem('selectedRevisionReasonsFilter');
  localStorage.removeItem('selectedServiceFilter');
  localStorage.removeItem('selectedUpdatedDateFilter');
  localStorage.removeItem('selectedCreatedDateFilter');
  localStorage.removeItem('clientNameSearchValue');
  localStorage.removeItem('createdBySearchValue');
  localStorage.removeItem('addressSearchValue');
  localStorage.removeItem('citySearchValue');
  localStorage.removeItem('email-message-dismissed');
  localStorage.removeItem('jobs-training-message-dismissed');
  localStorage.removeItem('assignmentFilterType');
  localStorage.removeItem('analyticsActiveTab');
  localStorage.removeItem('analyticsDateRange');
  localStorage.removeItem('analyticsUsersFilter');
  localStorage.removeItem('analyticsCompaniesFilter');
  localStorage.removeItem('analyticsActionType');
  localStorage.removeItem('servicesRequestedInterval');
  localStorage.removeItem('jobsCreatedInterval');
};
// *********

// ** Users **
export const getCurrentUser = async dispatch => {
  const responseData = await makeApiRequest('GET', '/users/me');
  Sentry.setUser({
    id: responseData.user.id,
    username: responseData.user.full_name,
    email: responseData.user.email
  });
  dispatch({
    type: ACTION_TYPES.UPDATE_USER,
    payload: responseData
  });
  dispatch({
    type: ACTION_TYPES.UPDATE_SITE_ALERTS,
    payload: responseData.site_alerts
  });
};

export const updateCurrentUserProfile = async (data, dispatch) => {
  const responseData = await makeApiRequest('PUT', '/users/me', data);
  dispatch({
    type: ACTION_TYPES.UPDATE_USER,
    payload: responseData
  });
};

export const updateUserOnboarding = async (dispatch, company = null) => {
  await makeApiRequest('PUT', '/users/me/onboard-finished', company);
  const responseData = await makeApiRequest('GET', '/users/me');
  dispatch({
    type: ACTION_TYPES.UPDATE_USER,
    payload: responseData
  });
};

export const getUserNotifications = async dispatch => {
  const userNotificationData = await makeApiRequest(
    'GET',
    '/notifications',
    null,
    { limit: 25, page: 1 }
  );
  dispatch({
    type: NOTIFICATION_ACTION_TYPES.UPDATE_USER_NOTIFICATIONS,
    payload: {
      notifications: userNotificationData.results,
      hasMoreNotifications: userNotificationData.next !== null
    }
  });
};

export const readNotification = async (id, data, dispatch) => {
  const responseData = await makeApiRequest(
    'PUT',
    `/notifications/${id}`,
    data
  );
  dispatch({
    type: NOTIFICATION_ACTION_TYPES.UPDATE_NOTIFICATION,
    payload: {
      updatedNotification: responseData
    }
  });
};

export const hideNotification = async (id, data, dispatch) => {
  const responseData = await makeApiRequest(
    'PUT',
    `/notifications/${id}`,
    data
  );
  dispatch({
    type: NOTIFICATION_ACTION_TYPES.HIDE_NOTIFICATION,
    payload: {
      hiddenNotification: responseData
    }
  });
};

export const loadMoreNotifications = async (pageNumber, dispatch) => {
  const moreNotifications = await makeApiRequest(
    'GET',
    '/notifications',
    null,
    {
      limit: 25,
      page: pageNumber
    }
  );
  dispatch({
    type: NOTIFICATION_ACTION_TYPES.LOAD_MORE_NOTIFICATIONS,
    payload: {
      notifications: moreNotifications.results,
      hasMoreNotifications: moreNotifications.next !== null,
      currentNotificationPage: pageNumber
    }
  });
};

export const updateAllNotifications = async (updateType, dispatch) => {
  await makeApiRequest('PUT', '/notifications', null, {
    action: updateType
  });
  const userNotificationData = await makeApiRequest(
    'GET',
    '/notifications',
    null,
    { limit: 25, page: 1 }
  );
  dispatch({
    type: NOTIFICATION_ACTION_TYPES.UPDATE_USER_NOTIFICATIONS,
    payload: {
      notifications: userNotificationData.results,
      hasMoreNotifications: userNotificationData.next !== null
    }
  });
};

export const getUsersTableData = async (
  dispatch,
  accountFilter,
  companyFilter,
  roleFilter
) => {
  const responseData = await makeApiRequest('GET', '/users', null, {
    account_filter: accountFilter,
    company_filter: companyFilter,
    role_filter: roleFilter
  });
  const companiesData = await makeApiRequest('GET', '/companies');
  dispatch({
    type: USERS_TYPES.LOADED,
    payload: {
      users: responseData,
      companies: companiesData
    }
  });
  return responseData;
};

export const getAllUsers = async (
  dispatch,
  accountFilter,
  companyFilter,
  roleFilter,
  includeSelf = false
) => {
  const responseData = await makeApiRequest('GET', '/users', null, {
    account_filter: accountFilter,
    company_filter: companyFilter,
    role_filter: roleFilter,
    ...(includeSelf && {
      include_self: true
    })
  });
  dispatch({
    type: USERS_TYPES.UPDATE_USERS_DATA,
    payload: {
      users: responseData
    }
  });
  return responseData;
};

export const getGroups = async dispatch => {
  const groupData = await makeApiRequest('GET', '/groups');
  dispatch({
    type: USERS_TYPES.GET_GROUPS,
    payload: {
      allGroups: groupData
    }
  });
  return groupData;
};

export const getActiveUsers = async dispatch => {
  const userData = await makeApiRequest('GET', '/users', null, {
    account_filter: 'active',
    admin_only: true,
    include_self: true,
    simplify_users: true
  });
  dispatch({
    type: USERS_TYPES.GET_ACTIVE_USERS,
    payload: {
      activeUsers: userData
    }
  });
  return userData;
};
// ********

// ** Jobs **
const sanitizeExistingJob = data => {
  const newData = data;
  newData.state = data.client_state ? data.client_state.id : '';
  newData.city = data.client_city ? data.client_city : '';
  newData.connection_type_id = data.connection_type
    ? data.connection_type.id
    : '';
  delete newData.panel_layout_images;
  delete newData.job_files;
  if (newData.rejection_notice) {
    delete newData.rejection_notice;
  }

  newData.arrays.forEach(array => {
    delete array.attic_images;
    delete array.beam_space_images;
    delete array.beam_size_images;
  });
  return newData;
};

export const updateJob = async data => {
  const newData = sanitizeExistingJob(data);

  const responseData = await makeApiRequest('PUT', `/jobs`, newData);
  return responseData;
};

export const reviseJob = async data => {
  const newData = sanitizeExistingJob(data);

  const responseData = await makeApiRequest(
    'POST',
    `/jobs/${newData.id}/revision`,
    newData
  );
  return responseData;
};

export const recalculateJob = async (job_id, arrays) => {
  try {
    const response = await makeApiRequest(
      'POST',
      `jobs/${job_id}/recalculate`,
      { arrays }
    );
    return response;
  } catch (e) {
    console.warn(e);
    throw e;
  }
};

export const recalculateJobWithUrl = async (job_id, arrays) => {
  try {
    await makeApiRequest('POST', `jobs/${job_id}/recalculate-url`, {
      arrays
    });
  } catch (e) {
    console.warn(e);
    throw e;
  }
};

// ********

// ** Codes **
export const getAllCodes = async dispatch => {
  const ibc = await makeApiRequest('GET', '/codes/ibc');
  const iebc = await makeApiRequest('GET', '/codes/iebc');
  const asce = await makeApiRequest('GET', '/codes/asce');
  const electricalCode = await makeApiRequest('GET', '/codes/electrical-code');
  const fireCode = await makeApiRequest('GET', '/codes/fire-code');
  dispatch({
    type: CODES_TYPES.LOADED,
    payload: {
      ibc,
      iebc,
      asce,
      electricalCode,
      fireCode
    }
  });
  return { ibc, iebc, asce, electricalCode, fireCode };
};

export const updateCode = async (data, type, dispatch) => {
  const responseData = await makeApiRequest('PUT', `/codes/${type}`, data);
  dispatch({
    type:
      type === 'ibc'
        ? CODES_TYPES.UPDATE_IBC_CODES
        : CODES_TYPES.UPDATE_IEBC_CODES,
    payload: { updatedCode: responseData }
  });
};
// **********

export const downloadFromS3 = async (url, fileName) => {
  axios
    .get(url, {
      responseType: 'blob'
    })
    .then(res => {
      fileDownload(res.data, fileName);
    });
};

export const makeAPIFileRequest = async url => {
  try {
    const response = await apiClient.get(url, {
      headers: {
        Authorization: getTokens().accessToken
          ? `Bearer ${getTokens().accessToken}`
          : ''
      },
      responseType: 'arraybuffer'
    });

    if (response.status === 200) {
      const contentType = response.headers['content-type'];
      const fileData = new Blob([response.data], { type: contentType });
      return {
        success: true,
        data: fileData
      };
    } else {
      return {
        success: false,
        error: `Request failed with status code ${response.status}`
      };
    }
  } catch (error) {
    if (error.response) {
      const decoder = new TextDecoder('utf-8');
      const errorData = decoder.decode(error.response.data);
      return {
        success: false,
        error: `An error occurred: ${error.message}`,
        errorData
      };
    } else {
      return {
        success: false,
        error: `An error occurred: ${error.message}`,
        errorData: null
      };
    }
  }
};

export const downloadFromS3Fetch = async (url, fileName) => {
  const request = new Request(url, {
    method: 'GET',
    mode: 'cors'
  });
  const fetchedRequest = await fetch(request);
  const blob = await fetchedRequest.blob();
  fileDownload(blob, fileName);
};

// *** Companies ***
export const getCompaniesTableData = async dispatch => {
  const responseData = await makeApiRequest('GET', '/companies', null, {
    getCompanyTableData: true
  });
  const allStates = await makeApiRequest('GET', '/states');
  const contactsData = await makeApiRequest('GET', '/companies/contacts');
  dispatch({
    type: COMPANIES_TYPES.LOADED,
    payload: {
      companies: responseData,
      states: allStates,
      supportContacts: contactsData
    }
  });
  return allStates;
};

export const updateUserCompanies = async dispatch => {
  const companyData = await makeApiRequest('GET', '/users/me');
  dispatch({
    type: JOBS_TYPES.UPDATE_COMPANIES,
    payload: {
      companies: companyData.companies
    }
  });
};

// ****** Analytics ******

export const getStats = async (
  dispatch,
  activeTab,
  setIsLoadingCallback,
  queryParams = {}
) => {
  try {
    setIsLoadingCallback(true);

    let { includedCompanies, includedUsers } = queryParams;

    if (includedUsers) {
      includedUsers = includedUsers
        .map(userGroup => {
          if (userGroup.users) {
            return userGroup.users.map(user => {
              return user.id;
            });
          } else if (userGroup.id) {
            return [userGroup.id];
          } else {
            return [userGroup];
          }
        })
        .flat();
    }

    if (includedCompanies) {
      includedCompanies = queryParams.includedCompanies.map(company => {
        return company.id || company;
      });
    }

    const responseData = await makeApiRequest('GET', '/stats', null, {
      active_tab: activeTab,
      start_date: queryParams.dateRange?.startDate,
      end_date: queryParams.dateRange?.endDate,
      servicesRequestedInterval: queryParams.servicesRequestedInterval,
      jobsCreatedInterval: queryParams.jobsCreatedInterval,
      included_users: JSON.stringify(includedUsers || []),
      included_companies: JSON.stringify(includedCompanies || []),
      show_design_stats: queryParams.showDesignStats
    });
    let actionType = null;
    switch (activeTab) {
      case 'My Stats':
        actionType = ANALYTICS_TYPES.UPDATE_USER_STATS;
        break;
      case 'Group Stats':
        actionType = ANALYTICS_TYPES.UPDATE_GROUP_STATS;
        break;
      case 'Company Stats':
        actionType = ANALYTICS_TYPES.UPDATE_COMPANY_STATS;
        break;
      default:
        break;
    }
    dispatch({
      type: actionType,
      payload: responseData
    });
    setIsLoadingCallback(false);
    return responseData;
  } catch (error) {
    setIsLoadingCallback(false);
    throw new ApiError({
      status: error?.response?.status,
      message: 'Error getting statistics from server!',
      data: error?.response?.data
    });
  }
};

export const getOptions = ({
  currentState,
  dispatch,
  options = { include: [], exclude: [] },
  onErrorCallback = error => {
    throw error;
  }
}) => {
  const urls = {
    connectionTypes: '/connection-types',
    states: '/states',
    structureTypes: '/structure-types',
    gypsumTypes: '/gypsum-types',
    roofMaterials: '/roof-materials',
    materialGrades: '/material-grades',
    beamShapes: '/beam-shapes',
    rackingTypes: '/racking-types',
    optimizerTypes: '/optimizer-types',
    inverterTypes: '/inverter-types',
    moduleTypes: '/module-types',
    batteryTypes: '/battery-types',
    mciTypes: '/mci-types'
  };

  dispatch({
    type: JOBS_TYPES.SET_IS_LOADING_OPTIONS,
    payload: true
  });

  const optionsToFetch =
    options.include?.length > 0
      ? options.include
      : Object.keys(urls).filter(option => !options.exclude.includes(option));

  const emptyOptions = optionsToFetch
    .map(option => ({ key: option, url: urls[option] }))
    .filter(option => currentState[option.key].length === 0);

  // Map over emptyOptions and make API requests
  const requests = emptyOptions.map(option => {
    return makeApiRequest('GET', option.url);
  });

  // Return a promise that resolves once all requests are finished
  return Promise.all(requests)
    .then(responses => {
      const payload = {};
      responses.forEach((response, index) => {
        payload[emptyOptions[index].key] = response;
      });

      dispatch({
        type: JOBS_TYPES.LOADED,
        payload
      });

      return payload;
    })
    .catch(error => {
      // Handle errors if any of the requests fail
      console.error('Error fetching options:', error);
      dispatch({
        type: JOBS_TYPES.SET_IS_LOADING_OPTIONS,
        payload: false
      });
      onErrorCallback(error);
    });
};

export const getParcelInfoByAddress = async (
  address,
  city,
  state,
  zip_code
) => {
  const responseData = await makeApiRequest('GET', '/parcel', null, {
    address,
    city,
    state: state.name ?? state,
    zip_code
  });
  return responseData;
};
